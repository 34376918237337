<template>
  <div class="registration wrapper auth-layout">
    <div class="registration-form">
      <b-link to="/">
        <div class="aml-logo">
          <img src="/images/aml-logo.svg" alt="aml" />
        </div>
      </b-link>
      <div class="form-wrapper">
        <h4 class="form-title">
          Пароль прострочений і повинен бути замінений!
        </h4>
        <h5 class="form-subtitle">
          Введіть новий пароль
        </h5>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)" class="mb-5">
            <div
              class="form-input-container"
              v-show="!email || email.length === 0"
            >
              <ValidationProvider
                vid="email"
                name="адреса електронної пошти"
                rules="required|email"
                v-slot="{ errors, dirty }"
              >
                <input
                  type="email"
                  v-model="email"
                  :disabled="true"
                  class="form__input"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="form-input-container form-input-container__password">
              <ValidationProvider
                vid="old_password"
                name="старий пароль"
                rules="required|password"
                v-slot="{ errors }"
              >
                <input
                  :type="oldPasswordFieldType"
                  v-model="old_password"
                  :state="errors.length > 0 ? false : null"
                  class="form__input form__input_primary form__input-password"
                  placeholder="Старий пароль"
                />
                <div
                  class="password-icon"
                  type="password"
                  @click="switchVisibility('old_password')"
                  :class="{
                    'form-input-container__password_open': showOldPassword,
                    'form-input-container__password_close': !showOldPassword
                  }"
                ></div>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="form-input-container form-input-container__password">
              <ValidationProvider
                vid="new_password"
                name="пароль"
                rules="required|password|confirmed:confirmation"
                v-slot="{ errors }"
              >
                <input
                  :type="newPasswordFieldType"
                  v-model="new_password"
                  :state="errors.length > 0 ? false : null"
                  class="form__input form__input_primary form__input-password"
                  placeholder="Новий пароль"
                />
                <div
                  class="password-icon"
                  type="password"
                  @click="switchVisibility('new_password')"
                  :class="{
                    'form-input-container__password_open': showNewPassword,
                    'form-input-container__password_close': !showNewPassword
                  }"
                ></div>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="form-input-container form-input-container__password">
              <ValidationProvider vid="confirmation" v-slot="{ errors }">
                <input
                  :type="confirmationPasswordFieldType"
                  v-model="password_confirmation"
                  :state="errors.length > 0 ? false : null"
                  class="form__input form__input_primary form__input-password"
                  placeholder="Повторіть новий пароль"
                />
                <div
                  class="password-icon"
                  type="password"
                  @click="switchVisibility('password_confirmation')"
                  :class="{
                    'form-input-container__password_open': showPasswordConfirmation,
                    'form-input-container__password_close': !showPasswordConfirmation
                  }"
                ></div>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <p class="">
              <b-link :to="{ name: 'ForgotPassword' }">
                Забули пароль?
              </b-link>
            </p>
            <button
              :disabled="submitting"
              variant="primary"
              type="submit"
              class="form__button"
            >
              <span v-if="!submitting">
                Змінити Пароль
              </span>
              <span v-else>
                Відправлення...
              </span>
            </button>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
    <div class="registration-video">
      <video
        class="aml-video"
        src="/video/aml-register.mp4"
        autoplay
        loop
      ></video>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$route.query.email) next({ name: "Login" });
      else next();
    });
  },
  name: "ResetExpiredPassword",
  created() {
    this.email = this.$route.query.email;
  },
  data: () => ({
    submitting: false,
    email: "",
    new_password: "",
    old_password: "",
    password_confirmation: "",
    showOldPassword: false,
    showNewPassword: false,
    showPasswordConfirmation: false,
    oldPasswordFieldType: "password",
    newPasswordFieldType: "password",
    confirmationPasswordFieldType: "password"
  }),
  methods: {
    switchVisibility(key) {
      if (key === "old_password") {
        this.oldPasswordFieldType =
          this.oldPasswordFieldType === "password" ? "text" : "password";
        this.showOldPassword = !this.showOldPassword;
      }

      if (key === "new_password") {
        this.newPasswordFieldType =
          this.newPasswordFieldType === "password" ? "text" : "password";
        this.showNewPassword = !this.showNewPassword;
      }

      if (key === "password_confirmation") {
        this.confirmationPasswordFieldType =
          this.confirmationPasswordFieldType === "password"
            ? "text"
            : "password";
        this.showPasswordConfirmation = !this.showPasswordConfirmation;
      }
    },
    onSubmit() {
      if (this.old_password === this.new_password) {
        this.submitting = false;
        this.$refs.form.setErrors({
          new_password: "Новий пароль не може співпадати з попереднім."
        });
        return;
      }

      this.submitting = true;
      api
        .resetExpiredPassword({
          email: this.email,
          old_password: this.old_password,
          new_password: this.new_password,
          new_password_confirmation: this.password_confirmation
        })
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch(error => {
          if (error.response.status !== 406) {
            this.submitting = false;
            return;
          }

          this.$refs.form.setErrors(error.response.data.errors);
          this.submitting = false;
        });
    }
  }
};
</script>

<style>
body,
p {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova";
}
* {
  transition: all 0.2s ease;
  box-sizing: border-box;
  color: inherit;
  text-decoration: none;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Light.ttf);
  font-weight: 300;
}
</style>

<style scoped>
.app {
  --gradient-to-color: #5bb836 !important;
  --gradient-from-color: #8279e7 !important;
  --gradient-color-stops: var(--gradient-from-color),
    var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  background-image: linear-gradient(
    to bottom right,
    var(--gradient-color-stops)
  ) !important;
}
a > img {
  width: 100px;
}
</style>
